import { FormOption } from '../types';

export const socketReconnectDelay = 2500;

export const urls = {
    api: process.env.REACT_APP_API_URL || 'https://api.jolpi.ca/ergast/f1',
};

export const sprintRounds = [5, 6, 11, 19, 21, 23];

export const defaultFormOption = (): FormOption => ({
    label: '',
    value: '',
});
