import { Circuit, CircuitResource, defaultCircuit } from '../Circuit/Circuit';
import { defaultSession, Session, SessionResource } from '../Session/Session';

export interface RaceResource {
    round: string;
    season: string;
    raceName: string;
    date: string;
    time: string;
    Circuit: CircuitResource;
    FirstPractice: SessionResource;
    SecondPractice?: SessionResource;
    ThirdPractice?: SessionResource;
    Qualifying: SessionResource;
    SprintQualifying?: SessionResource;
    Sprint?: SessionResource;
}

export interface Race {
    round: number;
    name: string;
    startDateTime: Date;
    endDateTime: Date;
    circuit: Circuit;
    firstPractice: Session;
    secondPractice?: Session;
    thirdPractice?: Session;
    qualifying: Session;
    sprintQualifying?: Session;
    sprint?: Session;
}

export const defaultRace = (): Race => ({
    round: 0,
    name: '',
    startDateTime: new Date(),
    endDateTime: new Date(),
    circuit: defaultCircuit(),
    firstPractice: defaultSession(),
    secondPractice: defaultSession(),
    thirdPractice: defaultSession(),
    qualifying: defaultSession(),
});
