import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import {
    ConstructorStandings,
    ConstructorStandingsResource,
    Standing,
    StandingsResource,
} from './ConstructorStandings';

export const transformToStanding = (
    standingsResource: StandingsResource,
    season: string,
): Standing => {
    const isChampion = season === '2024' && standingsResource.Constructor.constructorId === 'mclaren';

    return ({
        isChampion,
        position: Number(standingsResource.position),
        points: Number(standingsResource.points),
        constructor: transformToConstructor(standingsResource.Constructor),
    });
};

export const transformToConstructorStandings = (
    constructorStandingsResource: ConstructorStandingsResource,
): ConstructorStandings => {
    const standingsTabelResource = constructorStandingsResource.MRData.StandingsTable.StandingsLists[0];
    const standings = standingsTabelResource.ConstructorStandings.map(standingResource => (
        transformToStanding(standingResource, constructorStandingsResource.MRData.StandingsTable.season)
    ));

    return ({
        season: Number(constructorStandingsResource.MRData.StandingsTable.season),
        standings,
    });
};
