import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { DriverStandings, DriverStandingsResource } from './DriverStandings';
import { transformToDriverStandings } from './DriverStandingsTransformers';

let driverStandingsAbortController = new AbortController();

export const getDriverStandingsApiCall = async (): Promise<FetchCollectionResult<DriverStandings, string>> => {
    if (!isSSR) {
        driverStandingsAbortController.abort();
        driverStandingsAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: '/2024/driverStandings.json',
        });

        const response = await fetch(url, {
            signal: driverStandingsAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getDriverStandingsApiCall', response);
        }

        const driverStandingsResource: DriverStandingsResource = await response.json();
        const driverStandings = transformToDriverStandings(driverStandingsResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: driverStandings,
        };
    } catch (error: unknown) {
        console.error('[getDriverStandingsApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
